<template>
  <div id="employee-new-modal">
    <vs-popup class="popup90" :title="title" :active.sync="localShow">

      <permission-password style="z-index: 9999999999;" :show="popupPermissionPassword" title="ALERTA DE BAIXA RETROATIVA" icon="warning"
        actionButtonText="Prosseguir"
        @action="save(true)"
        @cancel="popupPermissionPassword = false">
      </permission-password>

      <!-- CADASTRO DE FORNECEDOR -->
      <vs-popup class="popup90" :fullscreen="false" title="Cadastrar Fornecedor" :active.sync="popupProvider">
        <div class="vx-row">
          <div class="vx-col md:w-1/5 w-full mt-0">
            <label class="vs-input--label">Tipo de Pessoa</label>
            <v-select v-model="provider.type" :reduce="option => option.value" :clearable="false"
              :options="[
                {label: 'Física', value: 'FISICA'},
                {label: 'Jurídica', value: 'JURIDICA'}]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
          <div class="vx-col md:w-2/5 w-full mt-0">
            <vs-input class="w-full mt-0" label="Nome/Razão Social" v-model="provider.name" data-vv-scope="provider" data-vv-as="Nome/Razão Social" v-validate.initial="'required'" name="name" />
            <span class="text-danger text-sm" v-show="errors.has('provider.name')">{{ errors.first('provider.name') }}</span>
          </div>
          <div class="vx-col md:w-1/5 w-full mt-0">
            <vs-input class="w-full" label="CNPJ/CPF" v-model="provider.cpf" v-mask="['###.###.###-##','##.###.###/####-##']" data-vv-scope="provider" data-vv-as="CPF" v-validate="'cpf'" name="cpf" />
            <span class="text-danger text-sm" v-show="errors.has('provider.cpf')">{{ errors.first('provider.cpf') }}</span>
          </div>
          <div class="vx-col md:w-1/5 w-full mt-0">
            <vs-input class="w-full" label="IE/RG" v-model="provider.rg" />
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-1/5 w-full" v-for="(phone,i) in provider.phones" :key="i">
            <vs-input class="w-full mt-2" v-mask="['(##)#####-####','(##)####-####']" :label="phone.type === 'phone' ? 'Telefone' : phone.type === 'mobile' ? 'Celular' : phone.type === 'other' ? 'Outro' : ''" v-model="phone.phone" />
          </div>
          <div class="vx-col md:w-1/5 w-full">
            <vs-input class="w-full mt-2" label="E-mail" data-vv-as="E-mail" v-model="provider.email" data-vv-scope="provider" type="email" v-validate="'email'" name="email" />
            <span class="text-danger text-sm" v-show="errors.has('provider.email')">{{ errors.first('provider.email') }}</span>
          </div>
          <div class="vx-col md:w-1/5 w-full mt-2">
            <label class="vs-input--label">Plano de contas</label>
            <v-select class="vue_select_drop_size_180" v-model="provider.account_plan_id"
              data-vv-as="Plano de Contas" data-vv-scope="provider" v-validate.initial="'required'" name="account_plan_id"
              :reduce="option => option.value" :options="accountPlans" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
            <span class="text-danger text-sm" v-show="errors.has('provider.account_plan_id')">Campo Obrigatório</span>
          </div>
        </div>

        <div class="flex items-end mt-2">
          <feather-icon icon="MapPinIcon" class="mr-2" svgClasses="w-5 h-5" />
          <span class="leading-none font-medium">Endereço</span>
        </div>
        <hr>

        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full mt-2">
            <vs-input class="w-full" label="CEP" placeholder="CEP" v-model="provider.address.zip_code" v-mask="['#####-###']" @keyup="searchZipCode" />
          </div>

          <div class="vx-col md:w-1/4 w-full mt-2">
            <label class="vs-input--label">Logradouro</label>
            <v-select v-model="provider.address.logradouro" :reduce="option => option.value" clearable :options="logradouroOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
          </div>

          <div class="vx-col md:w-1/4 w-full mt-2">
            <vs-input class="w-full" label="Endereço" v-model="provider.address.street" />
          </div>

          <div class="vx-col md:w-1/4 w-full mt-2">
            <vs-input class="w-full" label="Número" v-model="provider.address.number" type="number" placeholder="S/N" />
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/5 w-full">
            <vs-input class="w-full mt-2" label="Complemento" v-model="provider.address.complement" />
          </div>
          <div class="vx-col md:w-1/5 w-full">
            <vs-input class="w-full mt-2" label="Bairro" v-model="provider.address.neighborhood" />
          </div>
          <div class="vx-col md:w-1/4_ w-full mt-2">
            <label class="vs-input--label">Cidade</label>
            <v-select v-model="provider.address.city_id" :reduce="option => option.value" :placeholder="cityOptions.length ? 'Selecione' : 'Selecione um estado'" :options="cityOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <label class="vs-input--label">Estado</label>
            <v-select v-model="provider.address.state_id" :reduce="option => option.value" @input="fillCity(provider.address.state_id), provider.address.city_id = null" :options="states" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
          <div class="vx-col md:w-1/6 w-full mt-2">
            <label class="vs-input--label">País</label>
            <v-select v-model="provider.address.country" :reduce="option => option.value" :clearable="false" :options="countryOptions" :dir="$vs.rtl ? 'rtl' : 'ltr'">
              <template #no-options="{}">
                Desculpe, nenhum resultado encontrado.
              </template>
            </v-select>
          </div>
        </div>

        <vs-divider></vs-divider>

        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="mt-4 flex flex-wrap items-center justify-end">
              <vs-button color="secondary" class="ml-auto" @click="popupProvider = false">Cancelar</vs-button>
              <vs-button class="ml-4" @click="storeProvider" :disabled="!validateProvider || disableSave">Salvar Alterações</vs-button>
            </div>
          </div>
        </div>
      </vs-popup>

      <div class="flex items-start flex-col lg:flex-row">
        <div class="w-full">
          <div class="vx-row">
            <div class="vx-col md:w-1/5 w-full mt-0">
              <vs-input class="w-full mt-0" label="Nota Fiscal" v-model="fuelSupply.nota_fiscal" />
            </div>
            <div class="vx-col md:w-1/5 w-full mt-0">
              <vs-input type="date" class="w-full mt-0" label="Realização" data-vv-scope="supply" v-model="fuelSupply.date_of_supply" data-vv-as="Realização" v-validate.initial="'required'" name="date_of_supply" />
              <span class="text-danger text-sm" v-show="errors.has('supply.date_of_supply')">{{ errors.first('supply.date_of_supply') }}</span>
            </div>
            <div class="vx-col md:w-1/5 w-full mt-0">
              <vs-input type="number" min="0" class="w-full mt-0" label="KM Atual" data-vv-scope="supply" v-model="fuelSupply.current_mileage"
                data-vv-as="KM Atual" v-validate.initial="'required|min_value:0'" name="current_mileage" />
              <span class="text-danger text-sm" v-show="errors.has('supply.current_mileage')">Mínimo 0</span>
            </div>
            <div class="vx-col md:w-1/5 w-full mt-0">
              <vs-input type="number" class="w-full mt-0" label="Litros" data-vv-scope="supply" v-model="fuelSupply.liters"
                data-vv-as="Litros" v-validate.initial="'required|min_value:0'" name="liters" />
              <span class="text-danger text-sm" v-show="errors.has('supply.liters')">Mínimo 0</span>
            </div>
            <div class="vx-col md:w-1/5 w-full mt-0">
              <label class="vs-input--label">Combustível</label>
              <v-select v-model="fuelSupply.type_of_fuel" :reduce="option => option.value"
                data-vv-scope="supply" data-vv-as="Combustível" v-validate.initial="'required'" name="type_of_fuel" :clearable="false"
                :options="[ { value: 'Gasolina',     label: 'Gasolina' },
                            { value: 'Álcool',       label: 'Álcool' },
                            { value: 'Disel',        label: 'Disel' },
                            { value: 'Gás natural',  label: 'Gás natural' },
                            { value: 'Eletricidade', label: 'Eletricidade' }
                ]" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
              <span class="text-danger text-sm" v-show="errors.has('supply.type_of_fuel')">{{ errors.first('supply.type_of_fuel') }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-start flex-col lg:flex-row">
        <div class="w-full">
          <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mt-0">
              <label class="vs-input--label">Responsável</label>
              <v-select v-model="fuelSupply.employee_id" :reduce="option => option.value"
                data-vv-scope="supply" data-vv-as="Responsável" v-validate.initial="'required'" name="employee_id"
                :clearable="true" :options="employees" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
                <template #no-options="{}">
                  Desculpe, nenhum resultado encontrado.
                </template>
              </v-select>
              <span class="text-danger text-sm" v-show="errors.has('supply.employee_id')">{{ errors.first('supply.employee_id') }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-0">
              <label class="vs-input--label">Fornecedor</label>
              <div class="mt-0 flex flex-wrap items-center justify">
                <v-select class="w-90__" v-model="fuelSupply.provider_id" @search="debouncedGetSearchProvider" @option:selected="selectedProvider" :clearable="true" :filterable="false"
                  :reduce="option => option.value" :options="providerOptions" data-vv-scope="supply" data-vv-as="Fornecedor" v-validate.initial="'required'" name="provider_id"
                  placeholder="Digite CPF/CNPJ ou nome do fornecedor..." :dir="$vs.rtl ? 'rtl' : 'ltr'">
                  <template #no-options="{}">
                    Nenhum resultado encontrado.
                  </template>
                </v-select>
                <feather-icon @click="popupProvider = true" title="Novo Fornecedor" icon="UserPlusIcon" class="ml-auto inline-flex rounded-full" svgClasses="w-8 h-8 cursor-pointer hover:text-primary"></feather-icon>
              </div>
              <span class="text-danger text-sm" v-show="errors.has('supply.provider_id')">{{ errors.first('supply.provider_id') }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-0">
              <label class="vs-input--label">Total</label>
              <currency-input class="w-full vs-inputx vs-input--input normal hasValue"
                  v-model="fuelSupply.value_total"
                  :currency="{prefix: 'R$ '}"
                  :valueAsInteger="false"
                  :distractionFree="false"
                  :precision="2"
                  :autoDecimalMode="true"
                  :valueRange="{ min: 0 }"
                  :allowNegative="false" />
            </div>
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-full w-full">
          <vs-input class="w-full mt-2" label="Descrição" v-model="fuelSupply.observation" />
        </div>
      </div>

      <div class="flex items-end mt-2">
        <feather-icon icon="DollarSignIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Pagamento</span>
      </div>
      <hr class="mb-2">

      <!-- PARCELAS -->

      <div class="vx-row" :key="i" v-for="(parcel, i) in debit.transactions">
        <div class="vx-col md:w-1/6 w-full">
          <vs-input type="date" label="Vencimento" v-model="parcel.expiration_date"
            data-vv-as="Vencimento" data-vv-scope="supply" v-validate.initial="{ required: true }" :name="'expiration_date'+i" />
          <span class="text-danger text-sm" v-show="errors.has('supply.expiration_date'+i)">Campo obrigatório</span>
        </div>
        <div class="vx-col md:w-1/6 w-full">
          <vs-input type="date" label="Pago em" :disabled="!parcel.situation" v-model="parcel.payday" @change="checkPayday(parcel)"
          data-vv-as="Pago em" data-vv-scope="supply" v-validate.initial="{ required: parcel.situation }" :name="'payday'+i" />
          <span class="text-danger text-sm" v-show="errors.has('supply.payday'+i)">Campo obrigatório</span>
          <span class="text-danger text-sm" v-show="parcel.futureDate">Data futura não permitida</span>
        </div>
        <div class="vx-col md:w-1/6 w-full">
          <label class="vs-input--label">Situação</label>
          <div class="flex flex-wrap items-center justify-center">
            <vs-switch class="w-28 mt-2" icon-pack="feather" vs-icon-on="icon-check-circle" vs-icon-off="icon-slash"
              @input="parcel.situation ? parcel.value_pay = parcel.value_parcel : parcel.value_pay = 0;
              parcel.situation ? parcel.payday = today : parcel.payday = ''
              parcel.situation ? '' : parcel.futureDate = false;
              checkPayday(parcel)" v-model="parcel.situation">
              <span slot="off">ABERTA</span> <span slot="on">QUITADA</span>
            </vs-switch>
          </div>
        </div>

        <div class="vx-col md:w-1/6 w-full">
          <label class="vs-input--label">Forma Pagamento</label>
          <v-select class="vue_select_drop_size_100 w-48" :clearable="false" @input="filterAccountsParcels(i)" v-model="parcel.type_payment_id" :reduce="option => option.value" :options="typePayments" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
        <div class="vx-col md:w-1/6 w-full">
          <label class="vs-input--label">Conta</label>
          <v-select class="vue_select_drop_size_100 w-48" :clearable="false" :disabled="!parcel.type_payment_id" v-model="parcel.account_id"
            :reduce="option => option.value" :options="parcel.accountsFilteredOptions" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'"
            data-vv-as="Conta" data-vv-scope="supply" v-validate.initial="{ required: !parcel.account_id }" :name="'account_id'+i">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('supply.account_id'+i)">Campo obrigatório</span>
        </div>
        <div class="vx-col md:w-1/6 w-full">
          <label class="vs-input--label">Plano de Conta</label>
          <v-select class="vue_select_drop_size_100 w-48" :clearable="false" v-model="parcel.account_plan_id" :reduce="option => option.value" :options="accountPlans" placeholder="Selecione" :dir="$vs.rtl ? 'rtl' : 'ltr'">
            <template #no-options="{}">
              Nenhum resultado encontrado.
            </template>
          </v-select>
        </div>
      </div>

      <vs-divider></vs-divider>

      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-4 flex flex-wrap items-center justify-end">
          <vs-button class="ml-auto mt-2" @click="save()" :disabled="!validateForm">Cadastrar</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="secondary" @click="cancel">Cancelar</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import _ from 'lodash'
import moment from 'moment'
import { CurrencyInput } from 'vue-currency-input'
const permissionPassword = () => import(/* webpackChunkName: "permissionPassword" */ '@/components/permission-password/permissionPassword.vue')

import moduleEmployee           from '@/store/employee/moduleEmployee.js'
import moduleTypePayment from '@/store/type-payment/moduleTypePayment.js'
import moduleAccount from '@/store/account/moduleAccount.js'
import moduleAccountPlan from '@/store/account-plan/moduleAccountPlan.js'
import moduleFuelSupply from '@/store/fuel-supply/moduleFuelSupply.js'
import moduleStates from '@/store/state/moduleStates.js'
import moduleProvider from '@/store/provider/moduleProvider.js'

export default {
  name: 'new-medical-modal',

  components: {
    vSelect,
    CurrencyInput,
    permissionPassword
  },
  props: {
    show: {
      Boolean, default: false
    },
    title: {
      String, default: 'Diálogo'
    },
    vehicle: {
      Object, required: true
    }
  },

  computed: {
    validateProvider () {
      return !this.errors.any('provider')
    },
    serviceMaintenances () {
      return this.$store.getters['serviceMaintenance/forSelect']
    },
    employees () {
      return this.$store.getters['employee/forSelect']
    },
    validateForm () {
      return !this.errors.any('supply')
    },
    firstValue () { //controlo no watch para não permitir valor maior que o total
      return this.debit.firstParcelValue
    },
    typePayments () {
      return this.$store.getters['typePayment/forSelect']
    },
    accountPlans () {
      return this.$store.getters['accountPlan/forSelectFilterDespesa']
    },
    accounts () {
      return this.$store.getters['account/forSelect']
    },
    today () {
      const [DD, MM, YYYY] = new Date().toLocaleDateString('pt-br').split('/')
      return `${YYYY}-${MM}-${DD}`
    },
    states () {
      return this.$store.getters['states/getStates']
    }
  },

  data () {
    return {
      localShow: false,
      providerOptions: [],
      fuelSupply: {
        nota_fiscal: null,
        date_of_supply: '',
        current_mileage: 0,
        liters: 0,
        type_of_fuel: null,
        employee_id: null,
        provider_id: null,
        value_total: 0,
        observation: null,
        vehicle: this.vehicle
      },

      accountsFilteredOptions: [],
      popupPermissionPassword: false,
      currencyConfig:  {
        currency: {prefix: 'R$ '},
        valueAsInteger: false,
        distractionFree: false,
        precision: 2,
        autoDecimalMode: true,
        valueRange: { min: 0 },
        allowNegative: false
      },

      debit: {
        transactions: [
          {
            futureDate: false,
            type: 'DEBIT',
            expiration_date: this.today, //this.expiration_date.toISOString().slice(0, 10),
            payday: '',
            value_pay: 0,
            parcel: 1,
            value_parcel: 0,
            situation: false, // false = PENDING | true = PAID
            value_total: 0,
            observation: null,
            account_plan_id: null,
            account_id: null,
            type_payment_id: null,
            accountsFilteredOptions: this.accountsFilteredOptions
          }
        ]
      },
      disableSave: false,
      popupProvider: false,
      provider: {
        type: 'JURIDICA',
        name: null,
        cpf: null,
        rg: null,
        phones: [
          {type: 'mobile', phone: null},
          {type: 'phone', phone: null},
          {type: 'other', phone: null}
        ],
        email: null,
        account_plan_id: null,
        address: {
          type_address: 'COM',
          zip_code: null,
          logradouro: null,
          street: null,
          number: null,
          complement: null,
          neighborhood: null,
          city_id: null,
          state_id: null,
          country: 1 //Brasil
        }
      },
      countryOptions: [{ label: 'Brasil', value: 1 }],
      cityOptions:[],
      logradouroOptions: [
        { label: 'Rua', value: 'Rua' },
        { label: 'Avenida', value: 'Avenida' },
        { label: 'Rodovia', value: 'Rodovia' },
        { label: 'Estrada', value: 'Estrada' },
        { label: 'Lote', value: 'Lote' },
        { label: 'Servidão', value: 'Servidão' },
        { label: 'Quadra', value: 'Quadra' },
        { label: 'Travessa', value: 'Travessa' },
        { label: 'Linha', value: 'Linha' }
      ]
    }
  },

  watch: {
    show (val) {
      this.localShow = val
    },
    localShow (val) {
      if (!val) {
        this.fuelSupply = {
          nota_fiscal: null,
          date_of_supply: '',
          current_mileage: 0,
          liters: 0,
          type_of_fuel: null,
          employee_id: null,
          provider_id: null,
          value_total: 0,
          observation: null,
          vehicle: this.vehicle
        }

        this.debit = {
          transactions: [
            {
              futureDate: false,
              type: 'DEBIT',
              expiration_date: this.today, //this.expiration_date.toISOString().slice(0, 10),
              payday: '',
              value_pay: 0,
              parcel: 1,
              value_parcel: 0,
              situation: false, // false = PENDING | true = PAID
              value_total: 0,
              observation: null,
              account_plan_id: null,
              account_id: null,
              type_payment_id: null,
              accountsFilteredOptions: this.accountsFilteredOptions
            }
          ]
        }

        this.$validator.pause()
        //COMO OS CAMPOS APARECEM E SOMEM DINAMICAMENTE, PRECISO DO BLOCO ABAIXO PARA EVITAR ERROS DE VALIDAÇÃO
        this.$nextTick(() => {
          this.$validator.resume()
          this.$validator.errors.clear()
          this.$validator.validateScopes()
        })

        this.$emit('cancel')
      } else {
        this.$store.dispatch('typePayment/fetchAll').then(() => { }).catch(err => { console.error(err) })
        this.$store.dispatch('account/fetchAll').then(() => { }).catch(err => { console.error(err) })
        this.$store.dispatch('accountPlan/fetchAll').then(() => { }).catch(err => { console.error(err) })
        //ESTADO DEFAULT SC
        this.provider.address.state_id = 24
        this.fillCity(this.provider.address.state_id)
      }
    }
  },

  methods: {
    action () {
      this.$emit('action')
    },
    cancel () {
      this.$emit('cancel')
    },
    // SELECIONA O PLANO DE CONTAS CONFORME O PROVIDER
    selectedProvider () {
      const provider = this.providerOptions.find(e => e.value === this.fuelSupply.provider_id)
      if (provider) this.debit.transactions[0].account_plan_id = provider.account_plan_id
    },

    //EVITA BAIXA COM DATA FUTURA
    checkPayday (parcel) {
      if (moment(parcel.payday).year() > 1000) {
        const difDays = moment(parcel.payday).diff(moment(this.today), 'days')
        if (difDays > 0) {
          parcel.futureDate = true
          this.disableSave = true
        } else {
          parcel.futureDate = false
          //checa se há alguma parcela com pagamento futuro
          const index = this.debit.transactions.findIndex(e => e.futureDate)
          if (index === -1) this.disableSave = false
        }
      } else {
        parcel.futureDate = false
        //checa se há alguma parcela com pagamento futuro
        const index = this.debit.transactions.findIndex(e => e.futureDate)
        if (index === -1) this.disableSave = false
      }
    },

    isSameOrAfterToday (e) {
      if (moment(e).isValid()) return moment(e).isSameOrAfter(moment().format('YYYY-MM-DD'))
      return true
    },

    fetchproviderOptions (search, loading) {
      if (search.length) {
        loading(true)
        // busco alunos e fornecedores
        this.$http.post(`${process.env.VUE_APP_API_URL}/student/search`, { kw: search, type: 'PROVIDER' })
          .then(function (response) {
            this.providerOptions = []
            for (const k in response.data) {
              this.providerOptions.push({
                value: response.data[k].id,
                label: `${response.data[k].name} ${response.data[k].cpf || ''}`,
                account_plan_id: response.data[k].account_plan_id
              })
            }
            loading(false)
          }.bind(this))
          .catch(function (error) {
            console.error(error)
          })
      } else {
        loading(false)
      }
    },
    async save (pass = false) {
      try {
        /**
         * CHECAR PAGAMENTO RETROATIVO
         */
        if (!pass) {
          const retroactiveParcels = this.debit.transactions.filter(e => {
            if (moment(e.payday).diff(moment(this.today), 'days') < 0) return true
          })

          if (retroactiveParcels.length) {
            this.popupPermissionPassword = true
            this.$vs.notify({
              time: 6000,
              title: 'AVISO',
              text: 'Há parcelas com baixa retroativa',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
            return
          }
        }

        this.$vs.loading()
        this.fuelSupply.vehicle = this.vehicle // se não fizer isso vem lixo e dá erro
        await this.$store.dispatch('fuelSupply/store', { fuelSupply: this.fuelSupply, transaction: this.debit })
        this.$vs.loading.close()
        this.action()
        this.$vs.notify({
          time: 5000,
          title: 'SUCESSO',
          text: 'Dados Salvos.',
          color: 'success',
          iconPack: 'feather',
          icon: 'icon-check'
        })
        this.localShow = false
      } catch (error) {
        console.log(error)
        this.$vs.loading.close()
        if (error.response.status === 412) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
        } else {
          this.$vs.notify({
            time: 5000,
            title: 'Erro',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
    },

    // FILTRA AS CONTAS CONFORME O TIPO DE PAGAMENTO
    filterAccounts () {
      this.debit._id = null
      const myArrayFiltered = this.accounts.filter((account) => {
        return account.type_payments.some(e => e.id === this.debit.type_payment_id)
      })
      this.accountsFilteredOptions = myArrayFiltered
      if (myArrayFiltered.length) this.debit.account_id = myArrayFiltered[0].value
    },
    // FILTRA AS CONTAS NO FOR DAS PARCELAS CONFORME O TIPO DE PAGAMENTO
    filterAccountsParcels (i) {
      const parcel = this.debit.transactions[i]
      parcel.account = null
      const myArrayFiltered = this.accounts.filter((account) => {
        return account.type_payments.some(e => e.id === parcel.type_payment_id)
      })
      parcel.accountsFilteredOptions = myArrayFiltered
      if (myArrayFiltered.length) parcel.account_id = myArrayFiltered[0].value
    },
    storeProvider () {
      if (!this.validateProvider) return

      // Loading
      this.$vs.loading()
      this.disableSave = true
      setTimeout(() => { this.disableSave = false }, 3000) //TRAVA O BOTÃO SALVAR POR 3 SEGUNDOS

      this.$store.dispatch('provider/store', this.provider)
        .then((response) => {
          this.$vs.loading.close()
          this.popupProvider = false
          this.providerOptions.push({
            value: response.data.id,
            label: `${response.data.name} ${response.data.cpf}`,
            account_plan_id: response.data.account_plan_id
          })
          this.fuelSupply.provider_id = response.data.id

          this.$vs.notify({
            time: 5000,
            title: 'SUCESSO',
            text: 'Dados Salvos.',
            color: 'success',
            iconPack: 'feather',
            icon: 'icon-check'
          })
        })
        .catch(error => {
          this.$vs.loading.close()
          if (error.response.status === 412) {
            this.$vs.notify({
              time: 5000,
              title: 'AVISO',
              text: error.response.data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              time: 5000,
              title: 'Erro',
              text: error.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          }
        })
    },

    async searchZipCode ($event) {
      const zip_code = this.provider.address.zip_code.trim().replace(/[^0-9]/g, '')

      if (zip_code.length === 8 && ($event.keyCode < 37 || $event.keyCode > 40)) { // $event.keyCode evita chamadas de API ao teclar setas

        this.$http._noHeaders = true //enviando um get sem Autorization no header
        const resp = await this.$http.get(`https://viacep.com.br/ws/${zip_code}/json`)
        if (resp.data.erro) {
          this.$vs.notify({
            time: 5000,
            title: 'AVISO',
            text: 'Este CEP não existe ou não foi encontrado.',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          return
        }

        this.provider.address.neighborhood = resp.data.bairro
        this.provider.address.complement = resp.data.complemento

        //abaixo testo a primeira palavra da rua (logradouro), se existir removo da rua e seleciono o logradouro
        const rua = /Rua /i
        const avenida = /Avenida /i
        const rodovia = /Rodovia /i
        const estrada = /Estrada /i
        const lote = /Lote /i
        const servidao = /Servidão /i
        const quadra = /Quadra /i
        const travessa = /Travessa /i
        const linha = /Linha /i

        if (rua.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Rua'
          const regEx = new RegExp('Rua ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (avenida.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Avenida'
          const regEx = new RegExp('Avenida ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (rodovia.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Rodovia'
          const regEx = new RegExp('Rodovia ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (estrada.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Estrada'
          const regEx = new RegExp('Estrada ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (lote.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Lote'
          const regEx = new RegExp('Lote ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (servidao.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Servidão'
          const regEx = new RegExp('Servidão ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (quadra.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Quadra'
          const regEx = new RegExp('Quadra ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (travessa.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Travessa'
          const regEx = new RegExp('Travessa ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else if (linha.test(resp.data.logradouro)) {
          this.provider.address.logradouro = 'Linha'
          const regEx = new RegExp('Linha ', 'ig')
          this.provider.address.street = resp.data.logradouro.replace(regEx, '')
        } else {
          this.provider.address.street = resp.data.logradouro
        }

        const state = this.states.find(o => o.initials === resp.data.uf)
        this.provider.address.state_id = state.value
        await this.fillCity(state.value, resp.data.localidade)
      }
    },
    async fillCity (stateId, city = null) {
      this.cityOptions = []
      try {
        if (stateId) {
          const resp = await this.$http.get(`${process.env.VUE_APP_API_URL}/cities/${stateId}`)
          this.cityOptions.push({ value: null, label: 'Selecione' })

          for (const k in resp.data) {
            this.cityOptions.push({ value: resp.data[k].id, label: resp.data[k].city })
          }
          if (city) {
            //procura pelo id, se não encontrar peocura pelo nome (motivo: da api recebo ID e do CEP recebo nome)
            let ct = this.cityOptions.find(x => x.value === city)
            if (!ct) {
              ct = this.cityOptions.find(x => x.label === city)
            }
            this.provider.address.city_id = ct.value
          }
        }
      } catch (error) { console.error(error) }
    }
  },
  created () {
    this.debouncedGetSearchProvider = _.debounce(this.fetchproviderOptions, 600)

    if (!moduleEmployee.isRegistered) {
      this.$store.registerModule('employee', moduleEmployee)
      moduleEmployee.isRegistered = true
    }
    this.$store.dispatch('employee/fetchAll').then(() => { }).catch(err => { console.error(err) })

    if (!moduleTypePayment.isRegistered) {
      this.$store.registerModule('typePayment', moduleTypePayment)
      moduleTypePayment.isRegistered = true
    }
    if (!moduleAccount.isRegistered) {
      // this.$store.registerModule('account', moduleAccount)
      moduleAccount.isRegistered = true
    }
    if (!moduleAccountPlan.isRegistered) {
      // this.$store.registerModule('accountPlan', moduleAccountPlan)
      moduleAccountPlan.isRegistered = true
    }

    if (!moduleFuelSupply.isRegistered) {
      this.$store.registerModule('fuelSupply', moduleFuelSupply)
      moduleFuelSupply.isRegistered = true
    }

    if (!moduleStates.isRegistered) {
      this.$store.registerModule('states', moduleStates)
      moduleStates.isRegistered = true
    }
    this.$store.dispatch('states/fetchStates')

    if (!moduleProvider.isRegistered) {
      this.$store.registerModule('provider', moduleProvider)
      moduleProvider.isRegistered = true
    }
  }
}
</script>

<style>
  hr {
    margin-top: 2px;
    border: none;
    height: 1px;
    /* Set the hr color */
    color: rgb(204, 204, 204); /* old IE */
    background-color: rgb(204, 204, 204); /* Modern Browsers */
  }

  .popup90 .vs-popup {
    width: 90% !important;
  }

  .vue_select_drop_size_160 .vs__dropdown-menu {
    max-height: 160px;
  }
  .vs-tooltip {
    z-index: 99999;
  }
</style>
