var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "employee-new-modal" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup90",
          attrs: { title: _vm.title, active: _vm.localShow },
          on: {
            "update:active": function ($event) {
              _vm.localShow = $event
            },
          },
        },
        [
          _c("permission-password", {
            staticStyle: { "z-index": "9999999999" },
            attrs: {
              show: _vm.popupPermissionPassword,
              title: "ALERTA DE BAIXA RETROATIVA",
              icon: "warning",
              actionButtonText: "Prosseguir",
            },
            on: {
              action: function ($event) {
                return _vm.save(true)
              },
              cancel: function ($event) {
                _vm.popupPermissionPassword = false
              },
            },
          }),
          _c(
            "vs-popup",
            {
              staticClass: "popup90",
              attrs: {
                fullscreen: false,
                title: "Cadastrar Fornecedor",
                active: _vm.popupProvider,
              },
              on: {
                "update:active": function ($event) {
                  _vm.popupProvider = $event
                },
              },
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Tipo de Pessoa"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        reduce: (option) => option.value,
                        clearable: false,
                        options: [
                          { label: "Física", value: "FISICA" },
                          { label: "Jurídica", value: "JURIDICA" },
                        ],
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n              Desculpe, nenhum resultado encontrado.\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.provider.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider, "type", $$v)
                        },
                        expression: "provider.type",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-2/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      staticClass: "w-full mt-0",
                      attrs: {
                        label: "Nome/Razão Social",
                        "data-vv-scope": "provider",
                        "data-vv-as": "Nome/Razão Social",
                        name: "name",
                      },
                      model: {
                        value: _vm.provider.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider, "name", $$v)
                        },
                        expression: "provider.name",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("provider.name"),
                            expression: "errors.has('provider.name')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("provider.name")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["###.###.###-##", "##.###.###/####-##"],
                          expression: "['###.###.###-##','##.###.###/####-##']",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "cpf",
                          expression: "'cpf'",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: {
                        label: "CNPJ/CPF",
                        "data-vv-scope": "provider",
                        "data-vv-as": "CPF",
                        name: "cpf",
                      },
                      model: {
                        value: _vm.provider.cpf,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider, "cpf", $$v)
                        },
                        expression: "provider.cpf",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("provider.cpf"),
                            expression: "errors.has('provider.cpf')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("provider.cpf")))]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "IE/RG" },
                      model: {
                        value: _vm.provider.rg,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider, "rg", $$v)
                        },
                        expression: "provider.rg",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "vx-row" },
                [
                  _vm._l(_vm.provider.phones, function (phone, i) {
                    return _c(
                      "div",
                      { key: i, staticClass: "vx-col md:w-1/5 w-full" },
                      [
                        _c("vs-input", {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: ["(##)#####-####", "(##)####-####"],
                              expression: "['(##)#####-####','(##)####-####']",
                            },
                          ],
                          staticClass: "w-full mt-2",
                          attrs: {
                            label:
                              phone.type === "phone"
                                ? "Telefone"
                                : phone.type === "mobile"
                                ? "Celular"
                                : phone.type === "other"
                                ? "Outro"
                                : "",
                          },
                          model: {
                            value: phone.phone,
                            callback: function ($$v) {
                              _vm.$set(phone, "phone", $$v)
                            },
                            expression: "phone.phone",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/5 w-full" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "email",
                            expression: "'email'",
                          },
                        ],
                        staticClass: "w-full mt-2",
                        attrs: {
                          label: "E-mail",
                          "data-vv-as": "E-mail",
                          "data-vv-scope": "provider",
                          type: "email",
                          name: "email",
                        },
                        model: {
                          value: _vm.provider.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.provider, "email", $$v)
                          },
                          expression: "provider.email",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("provider.email"),
                              expression: "errors.has('provider.email')",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v(_vm._s(_vm.errors.first("provider.email")))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/5 w-full mt-2" },
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v("Plano de contas"),
                      ]),
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.initial",
                            value: "required",
                            expression: "'required'",
                            modifiers: { initial: true },
                          },
                        ],
                        staticClass: "vue_select_drop_size_180",
                        attrs: {
                          "data-vv-as": "Plano de Contas",
                          "data-vv-scope": "provider",
                          name: "account_plan_id",
                          reduce: (option) => option.value,
                          options: _vm.accountPlans,
                          placeholder: "Selecione",
                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "no-options",
                            fn: function ({}) {
                              return [
                                _vm._v(
                                  "\n              Desculpe, nenhum resultado encontrado.\n            "
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.provider.account_plan_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.provider, "account_plan_id", $$v)
                          },
                          expression: "provider.account_plan_id",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("provider.account_plan_id"),
                              expression:
                                "errors.has('provider.account_plan_id')",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [_vm._v("Campo Obrigatório")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "div",
                { staticClass: "flex items-end mt-2" },
                [
                  _c("feather-icon", {
                    staticClass: "mr-2",
                    attrs: { icon: "MapPinIcon", svgClasses: "w-5 h-5" },
                  }),
                  _c("span", { staticClass: "leading-none font-medium" }, [
                    _vm._v("Endereço"),
                  ]),
                ],
                1
              ),
              _c("hr"),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: ["#####-###"],
                          expression: "['#####-###']",
                        },
                      ],
                      staticClass: "w-full",
                      attrs: { label: "CEP", placeholder: "CEP" },
                      on: { keyup: _vm.searchZipCode },
                      model: {
                        value: _vm.provider.address.zip_code,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider.address, "zip_code", $$v)
                        },
                        expression: "provider.address.zip_code",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Logradouro"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        reduce: (option) => option.value,
                        clearable: "",
                        options: _vm.logradouroOptions,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n              Desculpe, nenhum resultado encontrado.\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.provider.address.logradouro,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider.address, "logradouro", $$v)
                        },
                        expression: "provider.address.logradouro",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { label: "Endereço" },
                      model: {
                        value: _vm.provider.address.street,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider.address, "street", $$v)
                        },
                        expression: "provider.address.street",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mt-2" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        label: "Número",
                        type: "number",
                        placeholder: "S/N",
                      },
                      model: {
                        value: _vm.provider.address.number,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider.address, "number", $$v)
                        },
                        expression: "provider.address.number",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full mt-2",
                      attrs: { label: "Complemento" },
                      model: {
                        value: _vm.provider.address.complement,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider.address, "complement", $$v)
                        },
                        expression: "provider.address.complement",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full mt-2",
                      attrs: { label: "Bairro" },
                      model: {
                        value: _vm.provider.address.neighborhood,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider.address, "neighborhood", $$v)
                        },
                        expression: "provider.address.neighborhood",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4_ w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Cidade"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        reduce: (option) => option.value,
                        placeholder: _vm.cityOptions.length
                          ? "Selecione"
                          : "Selecione um estado",
                        options: _vm.cityOptions,
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n              Desculpe, nenhum resultado encontrado.\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.provider.address.city_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider.address, "city_id", $$v)
                        },
                        expression: "provider.address.city_id",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Estado"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        reduce: (option) => option.value,
                        options: _vm.states,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      on: {
                        input: function ($event) {
                          _vm.fillCity(_vm.provider.address.state_id),
                            (_vm.provider.address.city_id = null)
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n              Desculpe, nenhum resultado encontrado.\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.provider.address.state_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider.address, "state_id", $$v)
                        },
                        expression: "provider.address.state_id",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/6 w-full mt-2" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("País"),
                    ]),
                    _c("v-select", {
                      attrs: {
                        reduce: (option) => option.value,
                        clearable: false,
                        options: _vm.countryOptions,
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n              Desculpe, nenhum resultado encontrado.\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.provider.address.country,
                        callback: function ($$v) {
                          _vm.$set(_vm.provider.address, "country", $$v)
                        },
                        expression: "provider.address.country",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("vs-divider"),
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mt-4 flex flex-wrap items-center justify-end",
                    },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "ml-auto",
                          attrs: { color: "secondary" },
                          on: {
                            click: function ($event) {
                              _vm.popupProvider = false
                            },
                          },
                        },
                        [_vm._v("Cancelar")]
                      ),
                      _c(
                        "vs-button",
                        {
                          staticClass: "ml-4",
                          attrs: {
                            disabled: !_vm.validateProvider || _vm.disableSave,
                          },
                          on: { click: _vm.storeProvider },
                        },
                        [_vm._v("Salvar Alterações")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "flex items-start flex-col lg:flex-row" }, [
            _c("div", { staticClass: "w-full" }, [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full mt-0",
                      attrs: { label: "Nota Fiscal" },
                      model: {
                        value: _vm.fuelSupply.nota_fiscal,
                        callback: function ($$v) {
                          _vm.$set(_vm.fuelSupply, "nota_fiscal", $$v)
                        },
                        expression: "fuelSupply.nota_fiscal",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      staticClass: "w-full mt-0",
                      attrs: {
                        type: "date",
                        label: "Realização",
                        "data-vv-scope": "supply",
                        "data-vv-as": "Realização",
                        name: "date_of_supply",
                      },
                      model: {
                        value: _vm.fuelSupply.date_of_supply,
                        callback: function ($$v) {
                          _vm.$set(_vm.fuelSupply, "date_of_supply", $$v)
                        },
                        expression: "fuelSupply.date_of_supply",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("supply.date_of_supply"),
                            expression: "errors.has('supply.date_of_supply')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.errors.first("supply.date_of_supply"))
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required|min_value:0",
                          expression: "'required|min_value:0'",
                          modifiers: { initial: true },
                        },
                      ],
                      staticClass: "w-full mt-0",
                      attrs: {
                        type: "number",
                        min: "0",
                        label: "KM Atual",
                        "data-vv-scope": "supply",
                        "data-vv-as": "KM Atual",
                        name: "current_mileage",
                      },
                      model: {
                        value: _vm.fuelSupply.current_mileage,
                        callback: function ($$v) {
                          _vm.$set(_vm.fuelSupply, "current_mileage", $$v)
                        },
                        expression: "fuelSupply.current_mileage",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("supply.current_mileage"),
                            expression: "errors.has('supply.current_mileage')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v("Mínimo 0")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required|min_value:0",
                          expression: "'required|min_value:0'",
                          modifiers: { initial: true },
                        },
                      ],
                      staticClass: "w-full mt-0",
                      attrs: {
                        type: "number",
                        label: "Litros",
                        "data-vv-scope": "supply",
                        "data-vv-as": "Litros",
                        name: "liters",
                      },
                      model: {
                        value: _vm.fuelSupply.liters,
                        callback: function ($$v) {
                          _vm.$set(_vm.fuelSupply, "liters", $$v)
                        },
                        expression: "fuelSupply.liters",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("supply.liters"),
                            expression: "errors.has('supply.liters')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v("Mínimo 0")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/5 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Combustível"),
                    ]),
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      attrs: {
                        reduce: (option) => option.value,
                        "data-vv-scope": "supply",
                        "data-vv-as": "Combustível",
                        name: "type_of_fuel",
                        clearable: false,
                        options: [
                          { value: "Gasolina", label: "Gasolina" },
                          { value: "Álcool", label: "Álcool" },
                          { value: "Disel", label: "Disel" },
                          { value: "Gás natural", label: "Gás natural" },
                          { value: "Eletricidade", label: "Eletricidade" },
                        ],
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n                Desculpe, nenhum resultado encontrado.\n              "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.fuelSupply.type_of_fuel,
                        callback: function ($$v) {
                          _vm.$set(_vm.fuelSupply, "type_of_fuel", $$v)
                        },
                        expression: "fuelSupply.type_of_fuel",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("supply.type_of_fuel"),
                            expression: "errors.has('supply.type_of_fuel')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("supply.type_of_fuel")))]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "flex items-start flex-col lg:flex-row" }, [
            _c("div", { staticClass: "w-full" }, [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Responsável"),
                    ]),
                    _c("v-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.initial",
                          value: "required",
                          expression: "'required'",
                          modifiers: { initial: true },
                        },
                      ],
                      attrs: {
                        reduce: (option) => option.value,
                        "data-vv-scope": "supply",
                        "data-vv-as": "Responsável",
                        name: "employee_id",
                        clearable: true,
                        options: _vm.employees,
                        placeholder: "Selecione",
                        dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n                Desculpe, nenhum resultado encontrado.\n              "
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.fuelSupply.employee_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.fuelSupply, "employee_id", $$v)
                        },
                        expression: "fuelSupply.employee_id",
                      },
                    }),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("supply.employee_id"),
                            expression: "errors.has('supply.employee_id')",
                          },
                        ],
                        staticClass: "text-danger text-sm",
                      },
                      [_vm._v(_vm._s(_vm.errors.first("supply.employee_id")))]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "vx-col md:w-1/3 w-full mt-0" }, [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v("Fornecedor"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-0 flex flex-wrap items-center justify" },
                    [
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.initial",
                            value: "required",
                            expression: "'required'",
                            modifiers: { initial: true },
                          },
                        ],
                        staticClass: "w-90__",
                        attrs: {
                          clearable: true,
                          filterable: false,
                          reduce: (option) => option.value,
                          options: _vm.providerOptions,
                          "data-vv-scope": "supply",
                          "data-vv-as": "Fornecedor",
                          name: "provider_id",
                          placeholder:
                            "Digite CPF/CNPJ ou nome do fornecedor...",
                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                        },
                        on: {
                          search: _vm.debouncedGetSearchProvider,
                          "option:selected": _vm.selectedProvider,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "no-options",
                            fn: function ({}) {
                              return [
                                _vm._v(
                                  "\n                  Nenhum resultado encontrado.\n                "
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.fuelSupply.provider_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.fuelSupply, "provider_id", $$v)
                          },
                          expression: "fuelSupply.provider_id",
                        },
                      }),
                      _c("feather-icon", {
                        staticClass: "ml-auto inline-flex rounded-full",
                        attrs: {
                          title: "Novo Fornecedor",
                          icon: "UserPlusIcon",
                          svgClasses:
                            "w-8 h-8 cursor-pointer hover:text-primary",
                        },
                        on: {
                          click: function ($event) {
                            _vm.popupProvider = true
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("supply.provider_id"),
                          expression: "errors.has('supply.provider_id')",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v(_vm._s(_vm.errors.first("supply.provider_id")))]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/3 w-full mt-0" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Total"),
                    ]),
                    _c("currency-input", {
                      staticClass:
                        "w-full vs-inputx vs-input--input normal hasValue",
                      attrs: {
                        currency: { prefix: "R$ " },
                        valueAsInteger: false,
                        distractionFree: false,
                        precision: 2,
                        autoDecimalMode: true,
                        valueRange: { min: 0 },
                        allowNegative: false,
                      },
                      model: {
                        value: _vm.fuelSupply.value_total,
                        callback: function ($$v) {
                          _vm.$set(_vm.fuelSupply, "value_total", $$v)
                        },
                        expression: "fuelSupply.value_total",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-2",
                  attrs: { label: "Descrição" },
                  model: {
                    value: _vm.fuelSupply.observation,
                    callback: function ($$v) {
                      _vm.$set(_vm.fuelSupply, "observation", $$v)
                    },
                    expression: "fuelSupply.observation",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "flex items-end mt-2" },
            [
              _c("feather-icon", {
                staticClass: "mr-2",
                attrs: { icon: "DollarSignIcon", svgClasses: "w-5 h-5" },
              }),
              _c("span", { staticClass: "leading-none font-medium" }, [
                _vm._v("Pagamento"),
              ]),
            ],
            1
          ),
          _c("hr", { staticClass: "mb-2" }),
          _vm._l(_vm.debit.transactions, function (parcel, i) {
            return _c("div", { key: i, staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col md:w-1/6 w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.initial",
                        value: { required: true },
                        expression: "{ required: true }",
                        modifiers: { initial: true },
                      },
                    ],
                    attrs: {
                      type: "date",
                      label: "Vencimento",
                      "data-vv-as": "Vencimento",
                      "data-vv-scope": "supply",
                      name: "expiration_date" + i,
                    },
                    model: {
                      value: parcel.expiration_date,
                      callback: function ($$v) {
                        _vm.$set(parcel, "expiration_date", $$v)
                      },
                      expression: "parcel.expiration_date",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("supply.expiration_date" + i),
                          expression: "errors.has('supply.expiration_date'+i)",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v("Campo obrigatório")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/6 w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.initial",
                        value: { required: parcel.situation },
                        expression: "{ required: parcel.situation }",
                        modifiers: { initial: true },
                      },
                    ],
                    attrs: {
                      type: "date",
                      label: "Pago em",
                      disabled: !parcel.situation,
                      "data-vv-as": "Pago em",
                      "data-vv-scope": "supply",
                      name: "payday" + i,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.checkPayday(parcel)
                      },
                    },
                    model: {
                      value: parcel.payday,
                      callback: function ($$v) {
                        _vm.$set(parcel, "payday", $$v)
                      },
                      expression: "parcel.payday",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("supply.payday" + i),
                          expression: "errors.has('supply.payday'+i)",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v("Campo obrigatório")]
                  ),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: parcel.futureDate,
                          expression: "parcel.futureDate",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v("Data futura não permitida")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "vx-col md:w-1/6 w-full" }, [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Situação"),
                ]),
                _c(
                  "div",
                  { staticClass: "flex flex-wrap items-center justify-center" },
                  [
                    _c(
                      "vs-switch",
                      {
                        staticClass: "w-28 mt-2",
                        attrs: {
                          "icon-pack": "feather",
                          "vs-icon-on": "icon-check-circle",
                          "vs-icon-off": "icon-slash",
                        },
                        on: {
                          input: function ($event) {
                            parcel.situation
                              ? (parcel.value_pay = parcel.value_parcel)
                              : (parcel.value_pay = 0)
                            parcel.situation
                              ? (parcel.payday = _vm.today)
                              : (parcel.payday = "")
                            parcel.situation ? "" : (parcel.futureDate = false)
                            _vm.checkPayday(parcel)
                          },
                        },
                        model: {
                          value: parcel.situation,
                          callback: function ($$v) {
                            _vm.$set(parcel, "situation", $$v)
                          },
                          expression: "parcel.situation",
                        },
                      },
                      [
                        _c("span", { attrs: { slot: "off" }, slot: "off" }, [
                          _vm._v("ABERTA"),
                        ]),
                        _c("span", { attrs: { slot: "on" }, slot: "on" }, [
                          _vm._v("QUITADA"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/6 w-full" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v("Forma Pagamento"),
                  ]),
                  _c("v-select", {
                    staticClass: "vue_select_drop_size_100 w-48",
                    attrs: {
                      clearable: false,
                      reduce: (option) => option.value,
                      options: _vm.typePayments,
                      placeholder: "Selecione",
                      dir: _vm.$vs.rtl ? "rtl" : "ltr",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.filterAccountsParcels(i)
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n            Nenhum resultado encontrado.\n          "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: parcel.type_payment_id,
                      callback: function ($$v) {
                        _vm.$set(parcel, "type_payment_id", $$v)
                      },
                      expression: "parcel.type_payment_id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/6 w-full" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v("Conta"),
                  ]),
                  _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.initial",
                        value: { required: !parcel.account_id },
                        expression: "{ required: !parcel.account_id }",
                        modifiers: { initial: true },
                      },
                    ],
                    staticClass: "vue_select_drop_size_100 w-48",
                    attrs: {
                      clearable: false,
                      disabled: !parcel.type_payment_id,
                      reduce: (option) => option.value,
                      options: parcel.accountsFilteredOptions,
                      placeholder: "Selecione",
                      dir: _vm.$vs.rtl ? "rtl" : "ltr",
                      "data-vv-as": "Conta",
                      "data-vv-scope": "supply",
                      name: "account_id" + i,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n            Nenhum resultado encontrado.\n          "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: parcel.account_id,
                      callback: function ($$v) {
                        _vm.$set(parcel, "account_id", $$v)
                      },
                      expression: "parcel.account_id",
                    },
                  }),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("supply.account_id" + i),
                          expression: "errors.has('supply.account_id'+i)",
                        },
                      ],
                      staticClass: "text-danger text-sm",
                    },
                    [_vm._v("Campo obrigatório")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vx-col md:w-1/6 w-full" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v("Plano de Conta"),
                  ]),
                  _c("v-select", {
                    staticClass: "vue_select_drop_size_100 w-48",
                    attrs: {
                      clearable: false,
                      reduce: (option) => option.value,
                      options: _vm.accountPlans,
                      placeholder: "Selecione",
                      dir: _vm.$vs.rtl ? "rtl" : "ltr",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "no-options",
                          fn: function ({}) {
                            return [
                              _vm._v(
                                "\n            Nenhum resultado encontrado.\n          "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: parcel.account_plan_id,
                      callback: function ($$v) {
                        _vm.$set(parcel, "account_plan_id", $$v)
                      },
                      expression: "parcel.account_plan_id",
                    },
                  }),
                ],
                1
              ),
            ])
          }),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto mt-2",
                      attrs: { disabled: !_vm.validateForm },
                      on: {
                        click: function ($event) {
                          return _vm.save()
                        },
                      },
                    },
                    [_vm._v("Cadastrar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4 mt-2",
                      attrs: { type: "border", color: "secondary" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("Cancelar")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }